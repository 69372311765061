.image{
    background-color: #FFFFFF;
    position: relative;
    flex: 1;
    /*max-width: 460px;*/
    width: 100% !important;
    height: 300px;
    margin: 20px;
    overflow: hidden;
    border:2px solid #F3F3F3;
    border-radius: 1rem !important;
    
   
  }
  .image:hover{
    
    border:2px outset #0180c2;
    
  }

  .image img{
    opacity: 0.8;
    position: relative;
    vertical-align:top;
    transition: 0.6s;
    transition-property: opacity;
    height: 350px;
    width: 100%;
    border-radius: 5px;
   
  }
  
  .image:hover img{
    opacity: 0;
   
    
    
  }
  
  .image .details{
    z-index: 1;
    position: absolute;
    top:0;
    right: 0;
    color:#0180c2;
    width: 100%;
    height: 100%;
  
  }
  
  .image .details h2{
    text-align: center;
    font-size: large;
    font-weight: 300;
    margin-top: 70px;
    transition: 0.4s;
    transition-property: transform;
  }
  .image .details h2 span{
    font-weight: 900;
  }
  .image:hover .details h2{
    transform:translateY(-30px);
  }
  
  .image .details p{
    margin:30px 30px 0 30px;
    font-size: small;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 600;
    text-align: center;
    opacity: 0;
    transition: 0.6s;
    transition-property: opacity,transform;
    
  }
  
  .image:hover .details p{
    opacity: 1;
    transform:translateY(-40px);
  }
  
  .more{
    position:absolute;
    background: rgba(255,255,255, 0.8);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    bottom:-60px;
    transition: 0.6s;
    transition-property: bottom;
  }
  .image:hover .more{
    bottom:0;
  }
  
  .more .read-more{
    color:#000;
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    z-index: +1 !important;
    
  }
  .more .read-more span{
    font-weight: 900;
  }
  .more .icon-links i{
    color:#000;
    font-size: 20px;
  }
  .more .icon-links a:not(:last-child) i{
    margin-right:15px;
  }
  
  
  @media (max-width: 1080px) {
    .image{
      flex: 100%;
      /*max-width: 480px;*/
    }
    
  }
  
  @media (max-width: 400px){
    .image .details p{
      font-size: 16px;
    }
    .more .read-more, .more .icon-links a i{
      font-size: 18px;
    }
  }
  
  